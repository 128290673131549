@import '_variables.scss';


body {
    background-color: $background-color;
    font-family: $body-main-fontfamily;
    margin: 0;
}

.content {
    background-color: $container-background-color;
    margin: 50px auto;
    width: 84%;
    padding: 1em;
}


.mainTitle {
    margin-left: 50px;
    h1 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 100;
        font-size: 42px;
        line-height: 49px;
        display: flex;
        align-items: center;
        letter-spacing: -0.24px;
    }
}

.highlightText {
    color: $highlight-color;
}

.titleNote {
    position: relative;
    top: -2.2em;
    font-size: smaller;
    left: 0.2em; 
}

@import '_ribbon.scss';
@import '_appcard.scss';
@import '_variables.scss';

.he-CardContainer {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.he-CardWrapper{
    flex: 0 1 calc(20% - 1em);
    box-sizing: border-box;
    margin: .5rem .5em;
}

.he-Card {
    width: $card-width;
    height: 100%;
    min-height: ($card-width * 1.2);
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
    outline: none;
    transition: box-shadow 0.5s ease 0s;
    font-family: Segoe UI;
    color: #323130;
    overflow: hidden;

    &:not(:first-child) {
        margin-left: 30px;
    }
}


.he-CardSection{
    margin: 0 12px;
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    width: auto;
    height: auto;
    margin-bottom: 1ex;;
    margin-top: 1ex;

    &.he-CardImage {
        width: 100%;
        height: $card-image-hight;
        padding: 12px;
        margin:0px;
        box-sizing: border-box;
        background-position: center center;
        background-color: $card-image-background-color;
        background-size: $card-image-background-imagesize;
        background-repeat: no-repeat;
        text-overflow: ellipsis;
        justify-content: flex-end;
    }

    .he-CardTitle {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .he-CardDescription {
        font-size: 12px;
        line-height: 20px;
    }
}